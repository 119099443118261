/** @jsx jsx */
import { jsx, css } from "@emotion/core";

export default function MyGithubButton() {
  return (
    <a
      css={css`
        display: flex;
        border: 2px solid;
        padding: 5px;
        border-radius: 7px;
      `}
      href="https://arexoft.com"
      role="button"
      // eslint-disable-next-line react/jsx-no-target-blank
      target="_blank"
    >
      {`arexoft.com`}
    </a>
  );
}
